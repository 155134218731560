import React from 'react';
import {Button} from '../Button';
import './MainPage.css';
import Footer from "../Footer";
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import isMobile from "../isMobile";


function MainPage() {
    const isMob = isMobile();
    if (isMob)
        return (
            <div className='mainpage-container-mobile'>
                <motion.div initial={{y: 120, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 120}}
                            transition={{default: {duration: 1}}}>
                    <div className='hero-container'>
                        <img src='/logo/logo_mainpage.png' alt='mainpage_logo'/>
                        <motion.div className='animated-text' initial={{opacity: 0}} animate={{opacity: 1}}
                                    transition={{default: {duration: 1}, delay: isMobile ? 0 : 2}}>
                            <h2>
                                “You must gather your party before venturing forth.”
                            </h2>
                        </motion.div>
                        <motion.div className='animated-text' initial={{opacity: 0}} animate={{opacity: 1}}
                                    transition={{default: {duration: 1}, delay: isMobile ? 0 : 2.5}}>
                            <h3>
                                – Baldur’s Gate (1998)
                            </h3>
                        </motion.div>
                        <motion.div className='hero-btns' initial={{opacity: 0}} animate={{opacity: 1}}
                                    transition={{default: {duration: 1}, delay: isMobile ? 0 : 3.5}}>
                            <Button
                                className='btns'
                                buttonSize='btn--mainpage'
                            >
                                <Link className='mainpage-links'
                                      to='/info'>
                                    Více informací
                                </Link>
                            </Button>
                        </motion.div>

                        <Footer/>
                    </div>
                </motion.div>
            </div>
        )
    else return(
        <div className='mainpage-container'>
            <video src="/backgrounds/main_page.mp4" autoPlay loop muted/>
            <motion.div initial={{y: 120, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 120}}
                        transition={{default: {duration: 1.5}}}>
                <div className='hero-container'>
                    <img src='/logo/logo_mainpage.png' alt='mainpage_logo'/>
                    <motion.div className='animated-text' initial={{opacity: 0}} animate={{opacity: 1}}
                                transition={{default: {duration: 1}, delay: isMobile ? 0 : 2}}>
                        <h2>
                            “You must gather your party before venturing forth.”
                        </h2>
                    </motion.div>
                    <motion.div className='animated-text' initial={{opacity: 0}} animate={{opacity: 1}}
                                transition={{default: {duration: 1}, delay: isMobile ? 0 : 2.5}}>
                        <h3>
                            – Baldur’s Gate (1998)
                        </h3>
                    </motion.div>
                    <motion.div className='hero-btns' initial={{opacity: 0}} animate={{opacity: 1}}
                                transition={{default: {duration: 1}, delay: isMobile ? 0 : 3.5}}>
                        <Button
                            className='btns'
                            buttonSize='btn--mainpage'
                        >
                            <Link className='mainpage-links'
                                  to='/info'>
                                Více informací
                            </Link>
                        </Button>
                    </motion.div>

                    <Footer/>
                </div>
            </motion.div>
        </div>
    )
}

export default MainPage;
