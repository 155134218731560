import { useState, useEffect } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add event listener for changes
    mediaQuery.addListener(handleMediaQueryChange);

    // Clean up
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;