import React from 'react';
import './Footer.css';
import {motion} from "framer-motion";

function Footer() {
    return (
        <div className='footer-container'>
            <motion.div
                        initial={{opacity: 0, y: -20}}
                        whileInView={{opacity: 1, y:0}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
        >
            <div className='footer-text-container'>
                <p className='footer-header'>
                    Připraveno spolkem Rolling
                </p>
                <p className='footer-text'>
                    Rolling, spolek pro rozvoj zážitkových vzdělávacích her, je nezisková organizace tvořená nadšenými
                    lidmi s dlouholetými zkušenostmi z oblasti pedagogiky, zážitkových projektů a občanského
                    vzdělávání. Zajišťuje celou tvorbu akce a její vnější komunikaci.
                    <br/>
                    Fučíkova 283, 411 55 Terezín, IČ 02296802, Bankovní spojení: 2300500209/2010; Vedený ve spolkovém
                    rejstříku u Krajského soudu v Hradci Králové sp. zn. L 8720
                </p>
                <br/>
                <p className='footer-text2'>
                    Narazili jste na webu na chybu, nebo máte nápad na jeho vylepšení? Napište nám!
                </p>
            </div>
            <div class='social-media-wrap'>
                <img src={'/logo/sf.png'} alt="sflogo" width='100px'/>
                <img src={'/logo/rolling.png'} alt='rollinglogo' width='100px' height='80px'/>
                <p className='footer-text3'>
                    Slzy Faerûnu, 2024
                </p>
                <div class='social-icons'>
                    <a href="src/components/Footer"><img src="./icons/fb.png" alt='fbicon' width="30px"/></a>
                </div>
                <div className='social-icons'>
                    <a href="mailto:slzyfaerunu@rolling.cz"><img src="./icons/mail.png" alt='mailicon' width="50px"/></a>
                </div>
            </div>
        </motion.div>
        </div>
    );
}

export default Footer;