import React, {useRef, useState} from 'react';
import "./Card.css";
import {Button} from "./Button";
import {motion} from "framer-motion";

const Card = ({emoji, heading, detail, contains, ytblink, characters, isReleased = false}) => {
    const [active, setActive] = useState("Front");
    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
        if (active === "Front")
            setActive("Back");
        else
            setActive("Front");
    }

    return (
        <div ref={ref} className='card'>
            {active === "Front" &&
                <motion.div key="box" initial={{opacity: 0}} animate={{opacity: 1}}
                            transition={{default: {duration: 0.5}, ease: "easeOut"}}>
                    <div className='card-front'>
                        <div className='card-upper'>
                            <img alt="icon" src={emoji} width='100px'/>
                            <div>
                                {heading}
                            </div>
                            <span>
                                {detail}
                            </span>
                        </div>
                        <div className='card-down'>
                            <text>
                                Hlavní motivy:
                            </text>
                            <br/>
                            <span>
                                {contains}
                            </span>
                            <br/>
                            <a href={ytblink}>
                                <img src="./icons/yt.png" alt='yticon' width="40px"/>
                            </a>
                            <br/>
                        </div>
                    </div>
                </motion.div>
            }
            {active === "Back" &&
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}}
                            transition={{default: {duration: 0.5}, ease: "easeOut"}}>
                    <div className='card-back'>
                        <div className='card-down'>
                            <text>
                                Postavy družiny {heading}:
                            </text>
                            <br/>
                            <div className='text-justified'>
                                <span>
                                    {characters}
                                </span>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
            {isReleased === true && active === "Front" &&
                <div>
                    <Button className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--companion'
                            onClick={handleClick}>
                        postavy družiny
                    </Button>
                </div>}
            {isReleased === true && active === "Back" &&
                <div>
                    <Button className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--companion'
                            onClick={handleClick}>
                        popis družiny
                    </Button>
                </div>
            }

            {isReleased === false &&
                <div className='card-down'>
                    <text>
                        Postavy družiny:
                    </text>
                    <br/>
                    <span className='pass-red'>
                        <text>
                            Brzy oznámíme
                        </text>
                    </span>
                </div>
            }
        </div>
    )
}
export default Card