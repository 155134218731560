import React from 'react';
import './AboutUs.css';
import AuthorCard from '../AuthorCard';
import {motion} from "framer-motion";

export default function AboutUs() {

    return (
        <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                    transition={{default: {duration: 0.5}}}>
            <div className='about-us-container'>
                <AuthorCard
                    heading={"Jan Mottl"}
                    detail={"Student Přírodovědecké fakulty Univerzity Hradec Králové, který stále studuje, aby mohl nakonec na té stejné univerzitě vzdělávat další pedagogy. Zároveň učí informatiku na jedné Pardubické škole. Již nějaký ten pátek jezdí na larpové akce, což ho prostě baví. Je to hlava tohoto cirkusu a hlavně se tedy zaměřuje na to, aby to celé dopadlo co nejlépe. Hodně rád se také soustředí na kreativní práci.\n"}
                    image={"./authors/Honza.png"}
                    isLeft={true}
                    hash="L89%q?%19FIp_MIV9G%L4:9aM{-:"
                />
                <AuthorCard
                    heading={"Anet Siny Formáčková"}
                    detail={"Nadšená pisatelka a nepostradatelná součást kreativního týmu. Je absolventkou Přírodovědecké fakulty Univerzity Karlovy a v současnosti pracuje ve vědě a výzkumu. Ve volném čase se ráda věnuje svým oblíbeným zábavám - larpům, kočkám a manželovi (těžko říct, jestli zrovna v tomhle pořadí). Na larpy jezdí přes patnáct let jako hráč a v poslední době experimentuje s možnostmi organizace akcí. "}
                    image={"./authors/Siny.png"}
                    isLeft={false}
                    hash="LEBC}h[8027h#%t8WAS%02K6~U+Z"
                />
                <AuthorCard
                    heading={"Michal Knížek "}
                    detail={"Michal je studentem zaměřujícím se na pivo. A to nejen po stránce konzumní, ale také z hlediska vzdělání, které již několik let nabývá na fakultě potravinářské a biochemické technologie na VŠCHT v Praze. K jeho koníčkům kromě již zmiňovaného piva patří taky chození do hor, DnD a hraní na kytaru. Sem tam se na nějakém larpu ochomýtnul jako hráč, což se mu zalíbilo, a tak se rozhodl prozkoumat i druhou stranu barikády. Na SF je součástí kreativního týmu."}
                    image={"./authors/Michal.png"}
                    isLeft={true}
                    hash="LBE{LgoL_Ns;.ANIIot6-n%2IBIp"

                />
                <AuthorCard
                    heading={"Matěj Pleskač"}
                    detail={"Student filmu a televize na UPOLu. K rolovým hrám se nachomýtnul už jako malé pískle, a jeho záliba v nich ho pár let nazpátek dotáhla k larpu. Nově zkouší larpy i organizovat, přičemž SF je jeho prvotina. Kromě toho k jeho koníčkům patří čtení a psaní, kreslení a další kreativní činnosti. "}
                    image={"./authors/Matej.png"}
                    isLeft={false}
                    hash="L6ATZe?GIUEy~Vo0kVW:00I.-V,["

                />

                <AuthorCard
                    heading={"Petr Formis Formáček"}
                    detail={"Stavař srdcem i hlavou z ČVUT v Praze, ale to neznamená, že by neměl čas na trochu toho poctivého dračáku, DnD, RPG či larpu. Tomuto koníčku se věnuje přes polovinu svého života a přestat rozhodně nehodlá. V týmu má co říci do kreativy, ale spíše jeho talenty vynikají v produkčních částech hry, kde se se pokusí vyrobit, sehnat, objednat, přivézt a odvézt vše, co si organizátoři či hráči vymyslí.  "}
                    image={"./authors/Formis.png"}
                    isLeft={true}
                    hash="L7A^5q4p01~U^0={4o%24T^jM|E2"

                />
                <AuthorCard
                    heading={"Lukáš Bachtík"}
                    detail={"Student Právnické fakulty Univerzity Karlovy a velký fanoušek stolních a počítačových her. V poslední době našel zálibu ve sbírání pravidel ke stolním hrám na hrdiny. K larpu se dostal díky hraní Dračího doupěte. Dříve se larpů účastnil jako hráč, případně jako pomocník. Tentokrát se rozhodl zkusit roli organizátora. Na SF je součástí kreativního týmu."}
                    image={"./authors/Bachta.png"}
                    isLeft={false}
                    hash="LNCPeNxuE2V@~Vt6D*WB%LaeIVR+"

                />
                <AuthorCard
                    heading={"Nikol Hot"}
                    detail={"Už pár let jezdí na larpové akce, a tak se sama rozhodla zapojit do organizace. Kromě larpu se věnuje i DnD, ráda tancuje a staví lego. "}
                    image={"./authors/Nikol.png"}
                    isLeft={true}
                    hash="LEC=@;024:~Bobs;E1-oE1%Ms99t"

                />
                <AuthorCard
                    heading={"Bára Zorka Jandová"}
                    detail={"Milovnice kostýmů a historického odívání. Na larpy jezdí už desátým rokem a za tu dobu si stihla i něco zorganizovat. V době covidové, kdy jí larpy velmi chyběly, se dostala k hraní DnD a tato záliba se změnila v menší závislost. Proto se těší, až pomůže spojit její oblíbený svět Forgotten realms s larpem. Kromě kreativy se bude věnovat i výrobě některých rekvizit či kostýmových doplňků. "}
                    image={"./authors/Zorka.png"}
                    isLeft={false}
                    hash="L4CsW?00y?%#.k8|Rj~V00~VE1?F"

                />
                <AuthorCard
                    heading={"Vítězslav Hušek"}
                    detail={"Student na FIT ČVUT, nadšenec do cestování a neobvyklých zážitků. Ve volném čase mistr mnoha řemesel, od úpravy videí až po tvorbu webů (třeba tohoto) a dalších menších či větších projektů. Pro Slzy Faerûnu primárně tvůrce a administrátor webu, specializovaná technická produkce pro hru (se zaměřením na lokaci 'Brána') a moderátor sociálních sítí, starající se o hladkou komunikaci mezi zájemci o náš larp a organizátory."}ssssssss
                    image={"./authors/Vita.png"}
                    isLeft={true}
                    hash="LMF58Y~V^*O8x|npSji_9~I]ajWA"

                />
                <AuthorCard
                    heading={"Matěj Dolion Baar"}
                    detail={"Je studentem filosofie na FF MUNI a průvodcem na hradě Kost. Současně je také milovníkem DnD a larpů a oběma aktivitám se věnuje přes deset let. Nedávno se rozhodl, že je na čase zapojit svou kreativitu a z tábora hráčů přeběhl do tábora orgů. Mezi jeho další záliby patří hra na klavír, historie a podnětné diskuse nad sklenkou lahodného moku."}
                    image={"./authors/Dolion.png"}
                    isLeft={false}
                    hash="L9A9~4KjO?%1~p.7TJ%LTe?ayCg3"
                />
                <AuthorCard
                    heading={"Vojtěch Axel Kratochvil"}
                    detail={"Normální človíček, který je tu na pomoc kamarádům s tímto úžasným projektem aby naplnil očekávání co nejvíce lidí co v ně vloží své naděje. Dračáky a LARP dělám už moc dlouho na to abych sám nezkusil s tvorbou něčeho nového a třeba uplatnil i nějakou tu nabranou zkušenost. Moc rád vás uvidím a třeba pokecáme po večeru v hostinci."}
                    image={"./authors/Axel.jpg"}
                    isLeft={true}
                    hash="L9A9~4KjO?%1~p.7TJ%LTe?ayCg3"
                    />
            </div>
        </motion.div>
    );
}
