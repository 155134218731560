import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';

export default function ImageComponent({src, alt, hash, width}) {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src;
    }, [src])


    return(
        <>
            <div style={{ display: imageLoaded ? ' none' : 'inline'}}>
                <Blurhash
                    hash={hash}
                    height={width}
                    width={width}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1} />
            </div>
            <img
                src={src}
                alt={alt}
                width={width}
                style={{ display: !imageLoaded ? ' none' : 'inline'}}
            />
        </>
    );
};