import "./StornoCalculator.css"
import React from "react";
import Swal from 'sweetalert2'



class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datum: '',
            balicek: '-1',
            kostym: '',
            protetika: '',
            zbran: '',
            beh: '-1'
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit(event) {
        if (this.state.datum === "") {
            Swal.fire({
                icon: "warning",
                color: "#bbbaba",
                text: 'Prosím zvolte datum',
                background: "#151313E5",
                confirmButtonColor: "#bbbaba",
                confirmButtonText: 'ok'
            })
            event.preventDefault();
            return
        }
        if (this.state.beh === "-1") {
            Swal.fire({
                icon: "warning",
                color: "#bbbaba",
                text: 'Prosím zvolte běh',
                background: "#151313E5",
                confirmButtonColor: "#bbbaba",
                confirmButtonText: 'ok'
            })
            event.preventDefault();
            return
        }
        if (this.state.balicek === "-1") {
            Swal.fire({
                icon: "warning",
                color: "#bbbaba",
                text: 'Prosím zvolte balíček',
                background: "#151313E5",
                confirmButtonColor: "#bbbaba",
                confirmButtonText: 'ok'
            })
            event.preventDefault();
            return
        } else {
            let celkem = 0
            let vratime = 0
            let cena = [3000, 3200, 3500, 4500, 8000]
            let beh = [new Date('2024-09-27'), new Date('2024-10-04')]
            let DaysBetween = ((beh[this.state.beh] - new Date(this.state.datum)) / 86400000)
            let TerminZaplaceni = new Date('2024-04-28')
            if (this.state.zbran) {
                celkem += 200
                vratime += 200
            }
            if (this.state.protetika) {
                celkem += 200
                vratime += 200
            }
            if (this.state.kostym) {
                celkem += 500
                vratime += 500
            }
            celkem += cena[this.state.balicek]

            if (DaysBetween <= 14)
                vratime += cena[this.state.balicek] * 0
            else if (DaysBetween <= 30)
                vratime += cena[this.state.balicek] * 0.5
            else if (new Date(this.state.datum) > TerminZaplaceni)
                vratime += cena[this.state.balicek] * 0.9
            else if (new Date(this.state.datum) <= TerminZaplaceni)
                vratime += cena[this.state.balicek]

            Swal.fire({
                icon: "info",
                color: "#bbbaba",
                text: ('Z celkové zaplacené částky ' + celkem + 'Kč \nvám při stornu vrátíme ' + vratime + 'Kč.'),
                background: "#151313E5",
                confirmButtonColor: "#bbbaba",
                confirmButtonText: 'ok'
            })
        }

        event.preventDefault();
    }

    render() {
        return (
            <div className="calculator">
                <form onSubmit={this.handleSubmit} className='form' noValidate>
                    <div>
                        <label className="calc-label"> Datum Odhlášení </label>
                        <input
                            className="select"
                            type='date'
                            name='datum'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label className="calc-label"> Běh </label>
                        <select className="select" onChange={this.handleChange} name='beh'>
                            <option selected value="-1">---</option>
                            <option value="0">3. běh</option>
                            <option value="1">4. běh</option>
                        </select>
                    </div>
                    <div>
                        <label className="calc-label"> Balíček </label>
                        <select className="select" onChange={this.handleChange} name='balicek'>
                            <option selected value="-1">---</option>
                            <option value="0">Dobrodruh</option>
                            <option value="1">Žoldnéř z Letohradu</option>
                            <option value="2">Hrdina z Baldurovy brány</option>
                            <option value="3">Lord z Panské aliance</option>
                            <option value="4">Božstvo Faerûnu</option>
                        </select>
                    </div>
                    <div>
                        <label className="calc-label"> Vypůjčení zbraně </label>
                        <input
                            className="select"
                            type='checkbox'
                            name='zbran'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label className="calc-label"> Vypůjčení kostýmu </label>
                        <input
                            className="select"
                            type='checkbox'
                            name='kostym'
                            onChange={this.handleChange}
                        /></div>
                    <div>
                        <label className="calc-label"> Vypůjčení protetiky </label>
                        <input
                            className="select"
                            type='checkbox'
                            name='protetika'
                            onChange={this.handleChange}
                        /></div>
                    <div>
                        <input className="select" type="submit" value="Vypočítat"/>

                    </div>
                </form>
                <br/>
            </div>


        )
    }
}

export default Calculator;