import React from 'react';
import './SignUp.css';
import {motion} from "framer-motion";
import RegCard from "../RegCard";

export default function SignUp() {
    return (
        <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                    transition={{default: {duration: 0.5}}}>
            <div className='cards__container'>

                <RegCard
                    icon={'./cards/player.png'}
                    heading={'Hráči'}
                    detail={<span className='pass'>
                            <text>Přihlašování pro letošní běhy je oficiálně uzavřeno!
                            <br/><br/>
                            Stále je však možné se hlásit jako náhradník s pomocí formuláře níže. Náhradníky sháníme pro případ, že by někdo z přihlášených hráčů nakonec nemohl dorazit.
                            </text>
                            </span>}
                    buttonText={"Formulář pro náhradníky"}
                    toLink={'https://forms.gle/4GsbgDrHFUeBh57U9'}
                    isReleased={true}
                />
                <RegCard
                    icon={'./cards/cp.png'}
                    heading={'Pomocníci (CP)'}
                    detail={<span className='pass'>
                                <text>
                                    Přihlašování je spuštěno!
                                    <br/><br/>
                                    Jako každý larp, ani my se neobejdeme bez pomocníků, kteří pomůžou ztvárnit některé vedlejší postavy, nebo vypomoct s realizací hry na místě.
                                    <br/><br/>
                                    Pomocníci, (dále označováni jako "CP") mají hru zdarma, nicméně se od nich očekává zodpovědnost a pomoc s různými pracemi během hry.
                                </text><br/>
                            </span>}
                    buttonText={"Info pro pomocníky"}
                    toLink={'/cp'}
                    isReleased={true}
                    button2={true}
                    buttonText2={"Formulář pro pomocníky"}
                    toLink2={"https://docs.google.com/forms/d/1ePK8BFU8NKe4HJbaVk_Aul5EOASkrY7jxZCEh68H2FE/viewform"}
                />
            </div>
        </motion.div>
    );
}
