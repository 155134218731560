import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {toast} from "react-toastify";


//toast.error('Došlo k změně termínů obou běhů! Více informací na stránce PRAKTICKÉ.', {theme:"colored"});
//toast.success('Registrace skupin jsou nyní uzavřené!', {theme:"dark"});
//toast.success('Přihlašování pro pomocníky je otevřeno!', {theme:"colored", position:"top-left"});
toast.info('Přihlašování pro pomocníky je otevřeno!', {theme:"colored", position:"top-left"});
ReactDOM.render(<App />, document.getElementById('root'));
