import React from 'react';
import {motion} from "framer-motion";
import "./CP.css";
import '../stars-background.css'
import {Button} from "../Button";
import {Link} from "react-router-dom";

export default function CP() {
    return (
        <div className='cp-page-container'>
            <div id='stars3'></div>

            <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                        transition={{default: {duration: 0.5}}}>

                <div className='cp-box-container'>
                    <h1>
                        Karta CP
                    </h1>
                    <div className='cp-columns'>
                        <div className='cp-div'>
                            <div className='cp-header'>
                                <img src="https://i.pinimg.com/originals/f0/e2/02/f0e202e0e83cccf938ba82d35c85ed33.png"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Zázemí
                                </h1>
                            </div>
                            <li>
                                Zázemí je v budově kousek od herní lokace. Některá CP budou moct spát přímo v budově,
                                ale
                                vzhledem k našemu počtu bude muset pár CP spát venku před budovou ve stanu.
                            </li>
                            <li>
                                Spí se na zemi ve vlastních spacácích, na místě bývají k dispozici deky a někdy i
                                matrace či
                                karimatky.
                            </li>

                            <li>
                                CP mají také k dispozici stan, kde probíhají brífingy, oblékání do kostýmů a další
                                příprava.
                            </li>
                            <li>
                                Na místě v budově jsou splachovací záchody a funkční umyvadla.
                            </li>
                            <li>
                                K dispozici je pitná voda z kohoutku. Areál není vybaven sprchami.
                            </li>
                            <li>
                                V zázemí jsou zásuvky a elektřina.
                            </li>
                            <li>
                                Hra probíhá primárně na venkovních lokacích. Vše je v docházkové vzdálenosti od zázemí.
                            </li>
                            <li>
                                Lokace nejdále jsou vzdáleny 10 minut chůze.
                            </li>
                            <li>
                                Hra je nekuřácká s výjimkou vyhrazených míst.
                            </li>
                        </div>
                        <div className='cp-div'>
                            <div className='cp-header'>
                                <img src="https://cdn-icons-png.flaticon.com/512/1460/1460449.png" width="25px"
                                     alt='ico'/>
                                <h1>
                                    Co dostanete?
                                </h1>
                            </div>
                            <li>
                                Na každou roli dostanete kostým a rekvizity, které jsou pro roli potřeba.
                            </li>
                            <li>
                                Během hry dostanete najíst (3 jídla denně + menší občerstvení nonstop na bázi, možnost
                                vegetariánské varianty)

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <div>
                                    <img src="./cards/cp.png" width="50px"
                                         alt='ico'/>
                                </div>
                                <h1>
                                    Jaké role budete ztvárňovat?
                                </h1>
                            </div>
                            <li>
                                Hra se dělí na úseky, které jsou vyloženě akční a na úseky, které jsou konverzační.
                                Budeme
                                rádi, pokud se nebudete bát hrát akčnější role, ale pokud se budete chtít boji vyhnout,
                                bude
                                pro vás i mnoho jiných rolí.
                            </li>
                            <li>
                                Hra se dělí na úseky, kde se primárně hraje kolem herní lokace a na úseky výprav
                                hráčských
                                družin. Ve hře je široká paleta rolí, kterou budete moct ztvárnit. Navíc některé cizí
                                postavy se do hry opakovaně vrací a tak si užijete určitou návaznost.

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://i.pinimg.com/564x/11/07/4f/11074ff326f4c11c3a46364aebb71306.jpg"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Co vás čeká kromě hraní rolí?
                                </h1>
                            </div>
                            <li>
                                Poprosíme vás o výpomoc na místě. Jedná se buď o pomoc v kuchyni (odnést jídlo, umýt
                                nádobí,
                                atd.) nebo o pomoc v zázemí (přenést nějakou krabici, roztřídit kostýmy, přišít knoflík,
                                poklidit nepořádek, odnést rekvizitu na herní lokaci, atd.).
                            </li>

                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://i.pinimg.com/564x/0f/7c/06/0f7c06b9c3465243ca7a8b3c1a1670b1.jpg"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Orientační harmonogram
                                </h1>
                            </div>
                            <li>
                                Příjezd pro hráče krátkodobých postav je v 10:00 hodin v pátek (případně velmi oceníme,
                                pokud byste mohli přijet dřív (čtvrtek večer/pátek ráno) a pomohli nám s přípravou hry).
                            </li>
                            <li>
                                Hned po příjezdu bude v pátek probíhat briefing. Řekneme si základní informace o hře,
                                zrekapitulujeme pravidla, seznámíte se s organizátory.
                            </li>
                            <li>
                                Hra probíhá od pátečního pozdního odpoledne (16:30) a trvá do sobotního večera (22:00).
                                V
                                pátek se hraje přibližně do půlnoci a v sobotu se vstává okolo 08:00 hodiny.
                            </li>
                            <li>
                                Večer v sobotu (po 22:30) je poherní párty a v neděli úklid. Prosíme všechny pomocníky,
                                aby
                                se na úklidu alespoň minimálně podíleli. S úklidem pomáhají i hráči.

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://i.pinimg.com/564x/a4/de/06/a4de0693c4b057d83b3b797b1853a3a3.jpg"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Co s sebou?
                                </h1>
                            </div>

                            <li>
                                Kostýmy neobsahují boty, je potřeba mít svoje. Vhodná je fantasy obuv v podobě
                                historických
                                (či historii inspirovaných) bot vysokých i nízkých. Ovšem fantasy obuv není nutnost a je
                                v
                                pořádku mít nerušivé pohorky, či kanady. Myslete na to, že budete běhat v lese, tak na
                                to
                                mějte uzpůsobenou obuv.
                            </li>
                            <li>
                                Pokud máte jakékoliv kusy fantasy kostýmů, tak je prosím vezměte. Primárně si zkuste
                                sehnat
                                spodní vrstvu (košili, spodní tuniku) a kalhoty, abyste na role mohli měnit jen vrchní
                                vrstvy, ale spodní část měli svou, protože vám bude lépe sedět a bude vám pohodlná.
                            </li>
                            <li>

                                Pro jistotu doporučujeme i teplé spodní vrstvy, zejména večer, kdy může být zima.
                            </li>
                            <li>
                                Jakékoliv doplňky ke kostýmu, které uznáte za vhodné. Případně vlastní věci na líčení a
                                úpravu vlasů.
                            </li>
                            <li>

                                Věci na spaní.
                            </li>
                            <li>
                                Pokud nosíte brýle/čočky, je dobré na hře využívat čočky (vzhledem k dobovému vizuálu).
                                Nutit ale nikoho nebudeme.
                            </li>
                            <br/>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://cdn-icons-png.flaticon.com/512/835/835503.png" width="25px"
                                     alt='ico'/>
                                <h1>
                                    Spánek a námaha:
                                </h1>
                            </div>
                            <li>
                                V pátek se jde spát okolo půlnoci a CP, která nebudou mít pozdní výstupy mohou jít spát
                                klidně dříve. V sobotu se vstává okolo 08:00 hodiny, zase poprosíme pár dobrovolníků,
                                kteří
                                by vstali, již okolo 07:00 ráno a pomohli produkci.
                            </li>
                            <li>
                                Hra není tolik fyzicky náročná, i když se to může na první pohled jevit. Všechny lokace
                                jsou
                                v docházkové vzdálenosti od báze. Navíc budete mít mezi výstupy pauzu si odpočinout.
                            </li>
                            <li>
                                Na hře se v několika scénách bojuje pomocí larpových zbraní, zejména na výpravách
                                družin.
                                Budeme hrozně rádi, když se akci nebudete bránit a občas nějaké akční CP zahrajete. Boje
                                jsou velmi jednoduché a pravidla jsou nastavena, aby boje byly bezpečné.

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img
                                    src="https://cdn3.iconfinder.com/data/icons/car-icons-front-views/451/Compact_Car_Front_View-512.png"
                                    width="25px"
                                    alt='ico'/>
                                <h1>
                                    Doprava
                                </h1>
                            </div>
                            <li>
                                Hra se odehrává v prostoru skanzenu Země keltů v Nasavrkách u Chrudimi – Nasavrky 409,
                                538
                                25 Nasavrky
                            </li>
                            <li>
                                Dopravu na místo si každý zajistí po své ose (na spolujízdě se bude možné dohodnout ve
                                skupině na FB).

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://cdn-icons-png.flaticon.com/512/5861/5861333.png" width="25px"
                                     alt='ico'/>
                                <h1>
                                    Jaké speciální role oceníme?
                                </h1>
                            </div>
                            <li>
                                Před a po hře budeme rádi za nějaké pomocníky při přípravě a úklidu scény (přenášení
                                věcí,
                                úklid).
                            </li>
                            <li>
                                Kostýmový a rekvizitní pomocník před hrou na pomoc s přípravou.
                            </li>
                            <li>
                                Zdravotníka, který během hry bude poskytovat základní první pomoc, pokud bude potřeba.

                            </li>
                        </div>
                        <div className='cp-div'>

                            <div className='cp-header'>
                                <img src="https://i.pinimg.com/564x/8a/f5/8c/8af58c5e9ec1b892d3bee56d5d9fac89.jpg"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Bonusy od nás pro vás
                                </h1>
                            </div>
                            <li>
                                Všechna CP si mohou říct fotografovi o portrét v kostýmu.
                            </li>
                            <li>
                                Každému CP dáme slevu 100 korun na účet v hospodě.

                            </li>
                        </div>
                        <div className='cp-div'>
                            <div className='cp-header'>
                                <img src="https://cdn3.iconfinder.com/data/icons/camera-109/32/Camera__Flash-512.png"
                                     width="25px"
                                     alt='ico'/>
                                <h1>
                                    Registrace:
                                </h1>
                            </div>
                            <p className='cp-paragraph'>
                                Zájemci o výpomoc se mohou registrovat na přes následující formulář:
                            </p>
                            <Link
                                to='https://docs.google.com/forms/d/1ePK8BFU8NKe4HJbaVk_Aul5EOASkrY7jxZCEh68H2FE/viewform'
                                target='_blank'
                                aria-label='cp-formular'>
                                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                                    Formulář pro CP
                                </Button>
                            </Link>
                        </div>

                    </div>
                </div>
            </motion.div>

        </div>
    )


}
