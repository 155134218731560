import WSPGallery from './Gallery';
import './Gallery.css';

function ILYIT() {

    const galleryImages = [
        {
            img: "./ilyittest/1.jpeg"
        },
        {
            img: "./ilyittest/2.jpeg"
        },
        {
            img: "./ilyittest/3.jpeg"
        },
        {
            img: "./ilyittest/4.jpg"
        },
        {
            img: "./ilyittest/5.jpg"
        }, {
            img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-105.webp?bwg=1704290037"
        },
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-166.webp?bwg=1704290038"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-182.webp?bwg=1704290038"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-033.webp?bwg=1704290037"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-065.webp?bwg=1704290037"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-053.webp?bwg=1704290037"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-051.webp?bwg=1704290037"},
        {img: "https://hajek.photo/wp-content/uploads/photo-gallery/larp/231006_Slzy_Faerunu-2.beh/Slzy_Faerunu-2.beh-106.webp?bwg=1704290037"}

    ]

    return (
        <div className="App">
            <br/>
            <br/><br/>

            <WSPGallery
                galleryImages={galleryImages}
            />

            <br/><br/>
        </div>
    );
}

export default ILYIT;