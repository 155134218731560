import React from 'react';
import {motion} from "framer-motion";
import "./Info.css";
import '../stars-background.css'
import Footer from "../Footer";

export default function Info() {
    return (
        <div className='info-page-container'>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                        transition={{default: {duration: 0.5}}}>

                <div className='info-box-container'>
                    <img className='info-logo' src='/logo/logo_mainpage.png' alt='mainpage_logo'/>
                    <div className='info-column'>
                        <p className='info-paragraph'>
                            Slzy Faerûnu je družinová obsahová rolová hra s prvky fantasy a silnou inspirací světem
                            Forgotten Realms. Hra je určena pro 48 hráčů, kteří jsou rozděleni do šesti družin po
                            osmi členech. Od pátečního odpoledne do sobotní noci budete moci prožít příběh našich
                            hrdinů.
                        </p>
                        <br/>
                        <p className='info-paragraph'>
                            Ve hře se setkáte s prvky akce, ale i komplexních vztahů mezi postavami. Vývoj postavy a
                            její rozhodnutí mají přímý vliv na konec vašeho společného příběhu.
                        </p>
                        <br/>
                        <p className='info-paragraph'>
                            Hra je zasazena do doby, kdy skončil magický mor a svět se stabilizoval, tedy až na
                            místo našeho příběhu. Pod Netherilskými horami mezi Dalekým lesem a Vysokým hvozdem
                            stojí osada Stříbrodol. Stříbrné pomezí (konfederace Luruar), které dlouhou dobu drželo
                            ochrannou ruku nad severem Faerûnu se rozpadlo. Nestability a chaosu využili orkové a
                            zaútočili. Kéž by však orkové byli jediným problémem, který naše hrdiny čeká…
                        </p>
                        <br/>
                    </div>
                </div>
                <Footer/>
            </motion.div>

        </div>

    )


}
