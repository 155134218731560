import React from 'react';
import Card from "../Card";
import './Companionship.css'
import {motion} from "framer-motion";

export default function Companionship() {
    return (
        <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                    transition={{default: {duration: 0.5}}}>

            <div className='cards__container'>
                <Card
                    emoji={'./cards/wd.png'}
                    heading={'Delegace z Waterdeepu'}
                    detail={
                        <span className='pass'>
                            <text>
                                Vzácná delegace je obklopena elegancí a respektem. Vznešený Maskovaný Lord z Waterdeepu přijíždí na důležité diplomatické jednání s místním vikomtem. Doprovází jej jeho slovutná rádkyně s učednicí, kterým magie není cizí a také ozbrojený doprovod žoldáckých mužů a žen různých původů, jejichž přísný pohled a ostré meče vzbuzují velkou úctu všech.
                                <br/>
                                Šeptá se však, že diplomacie není jediný cíl jejich mise. Zajímají je i staré artefakty z dávných dob a zdejší prostředí – hlavně prý problémy s příšerami v místním kraji. Ostřílení žoldnéři se jen třesou na tuhle dobře placenou práci, při které budou moci ukázat své síly. Snad jejich činy dojdou naplnění a místní obyvatelé budou moci zase klidně spát..
                            </text>
                        </span>}
                    contains={
                        <span className='pass'>
                            <text>
                                Intriky, Mystika, Vyjednávání, Cena přátelství, Bezmoc mocných, Boj, Pátrání
                            </text>
                        </span>}
                    ytblink={'https://youtu.be/-bH7buXxxkI'}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                                    <text>
                                        V čele družiny stojí Maskovaný Lord, dle jeho výšky nejspíše člověk, jenž kvůli zákonům Waterdeepu nikdy nesundává přilbu a neukazuje tvář. U pasu nosí zbraň, i když kvůli jeho postavení málokdy dochází na situace, že by ji musel použít.
                                        <br/>
                                        Po jeho boku se nachází vkusně oblečená lidská kouzelnice, která se těší všeobecnému uznání a úctě.
                                        <br/>
                                        Ozbrojený doprovod jim dělají zástupci z řádu Planoucí pěsti – statný půlork, který je po zuby ozbrojeným bojovníkem a zdatná hobitka, která dokáže odhalit i tu nejskrytější past a dostat celou družinu z průšvihů nejen bojem.
                                        <br/>
                                        Další členkou družiny je čarodějka opředená znaky elementů, snad jako by měla něco společného s genasi, nebo byla přímo jednou z nich.
                                        <br/>
                                        Nepřehlédnutelný je také charismatický člověk s pronikavým pohledem a možná až moc hbitýma rukama.
                                        <br/>
                                        Součástí družiny jsou také dva najatí žoldáci drsného vzhledu – drakorozená klerička, která vládne nejen zbraní, ale dokáže se postarat i o záchranu životů a zarputilý trpaslík, který je ošlehaným bojovníkem s mnohaletou zkušeností i když se o něm šeptá, že snad kdysi býval paladinem, než ztratil svou víru a ideály.
                                    </text>
                                </span>}
                />
                <Card
                    emoji={'./cards/mp.png'}
                    heading={'Mosazný praporec'}
                    detail={<span className='pass'>
                                <text>
                                    Luskan je domovem námořníků – městem svobody a volnosti, které zároveň zná ony tvrdé zvyky severu. Kdysi dávno se skupina mladých mořeplavců sešla na palubě jedné z velkých lodí a uzavřela pakt věčného přátelství. Jejich cesty se rozlétly daleko – někteří se vrátili do domoviny, jiní okusili štěstí u mocných organizací a někdo se vydali do dálek Torilu. Osud však tomu chtěl, aby se staří přátelé opět sešli a dali vzniknout Mosaznému praporci. Doprovázet karavany a dobrodruhy na cestách se zdálo jako snadný výdělek. Až na ten poslední kšeft… Poslední proklatej kšeft. A tak se zbytek družiny vrací z ledové Damary a s sebou si nesou nejen šrámy na těle, ale i na duši. K tomu všemu táhnou rakev s ostatky jednoho ze svých přátel, snad ve snaze jej přivést zpět k životu. Co se tak strašného mohlo stát v těch dalekých končinách? Je čas dokončit úkol, vyplatit poslední platiny a ukončit jedno staré přátelství. Otázka je jaký konec to bude?
                                </text>
                                </span>}
                    contains={<span className='pass'>
                                    <text>
                                        Silné přátelství a rodina; Honba za nadějí; Dobrodružství; Konec jedné velké společné cesty
                                    </text>
                                    </span>}
                    ytblink={"https://www.youtube.com/watch?v=hsJwQjWS48o"}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                                    <text>
                                        Skupinu ošlehanou chladnými vichry severu vede vysoká goliáška. Bojovnice, připravena se za každého člena své skupiny hádat a bít se na život i na smrt.
                                        <br/>
                                        Od záležitostí mystiky má skupina dvojici znalou arkány. Lidského mladíka, který procestoval svět. A ač je mladší, než ostatní a fyzicky nevyniká, jeho čarodějské umění vytáhlo skupinu z nejedné situace. Vedle něj kráčí žena s krví démonů, která vyvede pozorovatele hned několika věcmi. Že nosí roucho Mystického bratrstva je jedna věc, ale navíc, kdo kdy viděl tielfinga s parůžky?
                                        <br/>
                                        S partou vždy drží žena, která nikdy pořádně neopustila moře. Věčná námořnice s pronikavým pohledem a výmluvou pro každou situaci. A kde slova nestačí, tam promluví její dýka.
                                        <br/>
                                        Duševní útěchu a hojení ran družině přináší vodní genasi se symbolem Auril, klerička, která má ke své bohyni blízko svou samotnou podstatou.
                                        <br/>
                                        Dalším jest půlelf, který nejde pro ostré slovo daleko, a ještě dříve sahá po svém kordu. Mistr čepele, který možná až příliš lpí na minulost.
                                        <br/>
                                        Družina by asi nikdy nedošla daleko bez svého hraničáře. Tichý půlelf se znaky svého elfího původu z Podtemna (Underdark). Sice nemusí přímé slunce, ale temnou nocí vás dostane, kam budete chtít.
                                        <br/>
                                        A nakonec je tu poslední. Ten, který se ke skupině přidal až na jejich cestě. Muž z dálného severo-východu, který snad v družině vidí naději utéct před tím strašlivým mrazem. Nechť mu bohové přání splní, i když je otázkou, zda by se zrovna on k nim obrátil.
                                    </text>
                                </span>}
                />
                <Card
                    emoji={'./cards/os.png'}
                    heading={'Obránci Stříbrodolu'}
                    detail={<span className='pass'>
                            <text>
                                Obránci Stříbrodolu nejsou žádnými legendárními hrdiny ze slavných příběhů, které by se vyprávěly po celém světě, ale úplně obyčejní lidé, žijící v ještě obyčejnější vesnici. Život tam probíhal klidně a pomalu a jejich největší starostí bylo, zda-li snad nechodí sousedovic rošťáci krást úrodu. Nebezpečný a uspěchaný život dobrodruhů je nikdy nelákal. Nastává však čas, kdy budou muset obyvatelé Stříbrodolu čelit velké hrozbě. Po rozpadu konfederace Stříbrného pomezí se do kraje dostávají orkové a v místních lesí se skrývá mnoho dalších nebezpečenství. Část vesnice již odešla hledat bezpečí jinam. Zůstávají jen ti, kteří nemohou svůj domov opustit nebo chtějí za jeho bezpečí bojovat do posledního dechu.
                                <br/>
                                Spojence našli místní ve skupině elfů, kteří se zdají ztracení ve světě a hledají své místo. Jejich pomocná ruka v ochraně je vítaná, ale zdá se, že mají i své záměry a cíle. Postupně přinášejí do vesnice své zvyky a pravidla a zdá se, že jí chtějí převzít jako svůj nový domov. Ale co původní obyvatelé? Musí nejdřív vyřešit spory mezi sebou, než bude příliš pozdě a nebude už vesnice, kterou mají chránit.
                            </text>
                            </span>}
                    contains={<span className='pass'>
                                    <text>
                                        Rodina; Ochrana domova; Obhájení si místa ve světě; Křehké spojenectví; Nelehká rozhodnutí; Obyčejní lidé a hrdinové
                                    </text>
                                    </span>}
                    ytblink={"https://www.youtube.com/watch?v=UKorruH7mJQ&ab_channel=kaseysongs"}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                                    <text>
                                        Skupina, která žije ve Stříbrodolu se dělí na dvě části. Jedna bez druhé nemůže přežít, ale společně jim to vlastně také moc nefunguje.
                                        <br/>
                                        Z původních obyvatel Stříbrodolu vyčnívá svým vzezřením, ale i bojovými schopnostmi drakorozený strážný, který je znám pro svou čestnost. Jenže kvůli dobrému srdci a důvěřivosti se někdy stává terčem různých vtípků na jeho účet.
                                        <br/>
                                        Svou pomoc na ochraně Stříbrodolu nabídla i mladá lidská dívka, která byla vychována k víře a skromnosti. Zdá se, že ve vesnici ji má každý rád a navíc se před nedávnem stala novickou v místn kapli.
                                        <br/>
                                        Jejím opakem se zdá být vesnický vyvrhel - tiefling, na kterého si každý dává dobrý pozor. Přeci jen je to zplozenec pekla a démonům se nedá věřit.
                                        <br/>
                                        Toho vychovává jeho nevlastní otec, dobrý lovec a hraničář, který však čelí posměškům místních, že přijal pod střechu tu rohatou kukačku.
                                        <br/>
                                        Pojítkem mezi oběma částmi Obránců Stříbrodolu je půlelfí druidka, která miluje vše živé a uctívá přírodu. Už tu žije dlouho, ale zná i příchozí elfy, kterým chce pomoci.
                                        <br/>
                                        Ty vede ambiciźní půlelfka, která působí, že se před ničím nezastaví a snaží se ve Stříbrodolu prosadit, nejspíš by to tu celé ráda vedla.
                                        <br/>
                                        Doprovází ji sluneční elfka, která prý pochází z Stromuzměti. Kdo ví proč z ní odešla, ale vypadá, že po něčem urputně pátrá.
                                        <br/>
                                        Posledním členem elfské skupiny je starý zachmuřený Měsíční elf, který je na svou minulost velmi háklivý, nejspíš si prožil mnohé a hledá zde nový začátek.
                                    </text>
                                </span>}
                />
                <Card
                    emoji={'./cards/vd.png'}
                    heading={'Vikomtova družina'}
                    detail={<span className='pass'>
                                <text>
                                    Pár let nazpátek zanechal místní pán Stříbrodol a přilehlá území v rukou svého bratra. Zastesklo se mu po dobrodružstvích, která v minulosti zažíval, po všech těch honech a bojích a jiných rozptýleních, a proto opustil své honosné sídlo a odcestoval. Nyní se však vikomt musí do těchto zdivočelých krajin vrátit, aby po rozpadu konfederace zase nastolil pořádek. To však není jediným důvodem jeho návratu…
                                    <br/>
                                    Vikomt přijíždí v doprovodu skupiny ozbrojenců, ale i pár přátel, které si získal po cestě. Ne všichni se však z jeho návratu radují. Zdá se, že vikomtovu družinu zde nikdo vřele neuvítá, ba naopak se nad jeho rodinným sídlem stahují temná mračna a čeká na něj poslední rozloučení s jeho bratrem.
                                </text>
                            </span>}
                    contains={<span className='pass'>
                                <text>
                                    Horor; Vyšetřování; Odhalování minulosti; Vina a pomsta; Návrat domů
                                </text>
                            </span>}
                    ytblink={"https://www.youtube.com/watch?v=W2PMdgU7biM&ab_channel=KylieMinogue"}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                                    <text>
                                        V čele stojí vikomt, původem bohatýr odněkud z dalekého severu. Před mnoha a mnoha lety se ve Stříbrodolu usadil a nyní se na své sídlo vrací z cest zpět. A ač není nejmladší, ještě si pamatuje, jak se ohánět šavlí.
                                        <br/>
                                        Po jeho boku stojí ozbrojený doprovod v podobě dvou rytířů z řádu Rukavice. Statečný trpaslíčí břitmistr, který si potrpí na tradice, a lidská melancholická bojovnice.
                                        <br/>
                                        Nejsou tu však jediní, kteří se umí ohánět zbraní! K výpravě se připojila i paladinka genasi, nesoucí stopy elementu vzduchu. Vikomtovi složila přísahu věrnosti a nabídla mu svůj meč.
                                        <br/>
                                        Dále tu je zvláštně potetovaný tieflingský dobrodruh, pro nějž jsou divoké hvozdy druhým domovem.
                                        <br/>
                                        Družinu doprovází i pár přátel, které si vikomt získal po cestě. Hobití historička se zájmem v artefaktech a spisech z dávných dob, která ví, jak si pořádně užívat život. A její přítelkyně, půlelfí toulavá bardka, s okouzlujícím hlasem a velkým srdcem.
                                        <br/>
                                        Poslední se ke skupině přidal lidský vyšetřovatel, který má za úkol objasnit smrt vikomtova bratra. Detektiv za svůj život viděl mnohé, už nic ho nepřekvapí…
                                    </text>
                                </span>}
                />
                <Card
                    emoji={'./cards/zd.png'}
                    heading={'Ztracené duše'}
                    detail={<span className='pass'>
                                <text className='pass-cursive'>
                                    “Kdo,.. kdo jsi ty?”
                                    <br/>
                                    “A kdo, kdo jsem já!?”
                                    <br/>
                                </text>
                                <text>
                                    To budou otázky, které si všichni ze skupiny mnohokrát položí před tím, než odkryjí celou pravdu. A nebude přece jen nakonec lepší ona sladká nevědomost?
                                    <br/>
                                    Pestrá skupinka podivínů těch nejrůznějších barev a schopností. Na první pohled výprava plná dobrodruhů bezchybně fungující jako jeden muž. A přeci jen jim něco chybí. Někdo. Na místo vzpomínek mají v hlavách jen krátké záblesky toho, co se kdysi přihodilo. Jediné co je spojuje je sen. Sen, který se jim vkrádá do myslí každou noc, a je pouze prvním krokem na jejich dlouhé cestě, co je dovede až na samý okraj reality.
                                </text>
                            </span>}
                    contains={<span className='pass'>
                                <text>
                                    Hledání sebe sama; Mystika; Dlouhá cesta; Přátelství; Sny
                                </text>
                            </span>}
                    ytblink={"https://youtu.be/JPPE9jVtqmU"}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                                    <text>
                                        Vedoucí celého tohoto podivuhodného cirkusu není nikdo jiný, než větrem a léty ošlehaný muž s velkým nadáním pro arkánu a mystiku. Navenek chladný a odměřený mudrc, který je však pro mnohé ze skupiny nepostradatelnou otcovskou figurou, ke které rádi zavítají pro nějakou tu radu do života.
                                        <br/>
                                        O trochu té zábavy během dlouhých večerů u táboráku se se svými baladami a překrásnými písněmi stará sličná elfská bardka s jemně namodralým odstínem kůže, který prozrazuje její měsíční původ.
                                        <br/>
                                        Nepřehlédnutelnou součástí skupiny je charismatický a velmi ambiciózní ohnivý genasi, jenž kdysi jakožto paladin přísahal pomstu svému úhlavnímu nepříteli. Teď už jen stačí zjistit, kdo jeho úhlavním nepřítelem vlastně je.
                                        <br/>
                                        Další členkou je mladá, vždy usměvavá a trochu ztřeštěná gnómka, která chvíli neposedí a je pro každou legraci. Pokud zrovna na někoho nechystá žertík, pravděpodobně ji najdete hrabat se v jakémsi bláznivě geniálním zařízení, které ji zrovna napadlo sestrojit.
                                        <br/>
                                        Starý trpaslík, který jako by se stále cítil být čímsi vinen. Je to jen stihomam nebo ten zvláštní pocit někde hluboko v jeho hlavě má nějaké opodstatnění? Naštěstí je tu pro něj Helm. Ten má pro něj vždy pochopení a možná i proto šíří, jako klerik, víru v jeho jménu.
                                        <br/>
                                        V partě je i silná a neúprosná tabaxi bojovnice, ze které čiší surovost a odhodlání. Ne nadarmo se proslýchají příběhy o tom, že umí svými drápky zasadit velmi tvrdou ránu.
                                        <br/>
                                        Proradná i něžná. Právě taková je členka, které bezpochyby koluje v žilách trochu démonské krve. Temně rudá pokožka, ocas a růžky jsou zaručeným receptem na potíže.
                                        <br/>
                                        A nakonec milý, přátelský a všemi oblíbený druid. Půlčík, který sice zvorá na co přijde, ale svým ostrovtipem a pohotovostí dokáže často zdánlivě bezvýchodnou situaci vždy odlehčit a pozvednout tak náladu celé skupiny.

                                    </text>
                                </span>}
                />
                <Card
                    emoji={'./cards/cs.png'}
                    heading={'Čaroknechti ze Spellguardu'}
                    detail={<span className='pass'>
                                <text>
                                    Každý jistě slyšel o slovutné organizaci Spellguard, která střeží, chrání, brání před vším, co je jen trochu cítit zlou magií a mohlo by ublížit či dokonce ovládnout nezkušeného tvora. Proto ze severu přichází do Stříbrodolu skupina čaroknechtů, aby pomohla zdejšímu kraji od všelijakých neduhů a to především těch magického charakteru.
                                    <br/>
                                    Kdo by však čekal zpupně uhlazenou družinku, která bude jen rozkazovat a na vše koukat shora, hluboce se plete. Čaroknechti jsou ostřílení dobrodruzi, kteří, už jen podle vzhledu, umí se vším co se jim postaví do cesty zatočit. Leckdo by si je snad mohl splést se skupinou lapků či hrdlořezů.
                                    <br/>
                                    Těžko se domnívat, jak se zrovna tito družiníci dali dohromady. Každému, kdo se zeptá na jejich poslání, se dostane odpovědi:
                                    <br/>
                                </text>
                                <text className='pass-cursive'>
                                    “Zničit vše špatné”
                                </text>
                                <text>
                                    <br/>
                                    Insignie a glejt království Stříbroluní mluví za své!
                                </text>
                            </span>}
                    contains={<span className='pass'>
                                <text>
                                    Mystika; Vyhrocené vztahy; Lidskost, Božství; Co je zlo a co dobro?
                                </text>
                            </span>}
                    ytblink={"https://youtu.be/-nbq6Ur103Q"}
                    isReleased={true}
                    characters={
                        <span className='pass'>
                            <text>
                                Skupinu vede starý gnóm, v jehož výzbroji člověk nenajde věc, která by nebyla magicky očarována. Stejně je na tom i jeho smrtonosná zbraň, děsivý vynález pocházející nejspíše z ostrova Lantan.
                                <br/>
                                Mluvení a vydávání rozkazů ale gnóm přenechává svému elfímu druhovi. V očích se mu leskne stáří a moudrost, jako by snad zažil samotný pád Netherilské říše. Je zřejmé, že se jedná o mocného kouzelníka.
                                <br/>
                                V závěsu za těmito dvěma často bývá trpaslice s nenechavými prsty, jež nese podivnou zlatou lebku (snad jako by si s ní povídala?) a zvláštně pobledlý genasi s nadáním pro živelné elementy.
                                <br/>
                                Poslední kdo nese v družině insignii Spellguardu je mladičká elfka s jiskrným pohledem a veselou náturou.
                                <br/>
                                Té se od boku nehne kolosální goliáška, ze které číší zkušenosti z mnoha výzev a nejen těch bojových. Trpělivě vyčkává, kdy přijde další, kterou je třeba splnit.
                                <br/>
                                V družině je dále půlorčice z řádu rytířů ve stříbrném, která má rovněž nejednu bitvu za sebou. Dokazuje to i šrám na obličeji a páska přes oko.
                                <br/>
                                Ze stejného rytířského řádu je poslední člen, charismatický člověk a zdatný šermíř. V jeho očích snad jako by se lesklo samo zlato.
                            </text>
                        </span>}
                />
            </div>
        </motion.div>
    );
}
