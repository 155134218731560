import React from 'react';
import "./Card.css";
import {Link} from "react-router-dom";
import {Button} from "./Button";

const RegCard = ({icon, heading, detail, buttonText, isReleased = false, toLink, toLink2, buttonText2, button2}) => {
    return (
        <div className='card'>
            <div className='card-upper'>
                <img src={icon} alt="icon" width='100px'/>
                <div>
                    {heading}
                </div>
                <span>
                    {detail}
                </span>
            </div>
            {isReleased === true &&
                <div className='card-down'>
                    <Link
                        to={toLink}
                        aria-label='formular'>
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                            {buttonText}
                        </Button>
                    </Link>
                    {button2 === true &&
                        <div>
                            <br/>
                        <Link
                            to={toLink2}
                            aria-label='formular'>
                            <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                                {buttonText2}
                            </Button>
                        </Link>
                        </div>
                    }
                    <br/>
                </div>
            }
        </div>
    )
}
export default RegCard;