import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import AboutUs from "./components/pages/AboutUs";
import Companionship from "./components/pages/Companionship";
import Practic from "./components/pages/Practic";
import SignUp from "./components/pages/SignUp";
import AboutProject from "./components/pages/AboutProject";
import {AnimatePresence} from "framer-motion";
import MainPage from "./components/pages/MainPage";
import Info from "./components/pages/Info";

import CP from "./components/pages/CP";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedirectGoogle from "./google";
import ILYIT from "./components/pages/DebugTestPage";
import isMobile from "./components/isMobile";

function App() {
    const location = useLocation;
    const isMob = isMobile();
    if (isMob)
        return (
            <AnimatePresence>
                <ToastContainer/>
                <div className="black-bg">
                <Router location={location} key={location.pathname}>
                    <Navbar/>
                    <Routes>
                        <Route path='*' element={<MainPage/>}/>
                        <Route path='/' element={<MainPage/>}/>
                        <Route path='/about-us' element={<AboutUs/>}/>
                        <Route path='/companionship' element={<Companionship/>}/>
                        <Route path='/about-project' element={<AboutProject/>}/>
                        <Route path='/sign-up' element={<SignUp/>}/>
                        <Route path='/practic' element={<Practic/>}/>
                        <Route path='/google5f564e9bf3cda359.html' component={RedirectGoogle}/>
                        <Route path='/info' element={<Info/>}/>
                        <Route path='/cp' element={<CP/>}/>
                        <Route path='/ilyit' element={<ILYIT/>}/>
                    </Routes>
                </Router>
                    </div>
            </AnimatePresence>
        )
    else
        return (
            <AnimatePresence>
                <ToastContainer/>
                <video src="/backgrounds/side_pages.mp4" autoPlay loop muted/>
                <Router location={location} key={location.pathname}>
                    <Navbar/>
                    <Routes>
                        <Route path='*' element={<MainPage/>}/>
                        <Route path='/' element={<MainPage/>}/>
                        <Route path='/about-us' element={<AboutUs/>}/>
                        <Route path='/companionship' element={<Companionship/>}/>
                        <Route path='/about-project' element={<AboutProject/>}/>
                        <Route path='/sign-up' element={<SignUp/>}/>
                        <Route path='/practic' element={<Practic/>}/>
                        <Route path='/google5f564e9bf3cda359.html' component={RedirectGoogle}/>
                        <Route path='/info' element={<Info/>}/>
                        <Route path='/cp' element={<CP/>}/>
                        <Route path='/ilyit' element={<ILYIT/>}/>
                    </Routes>
                </Router>
            </AnimatePresence>
        )
}

export default App;

