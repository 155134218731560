import {useCallback, useEffect, useState} from 'react'
import {motion} from "framer-motion";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleChevronLeft, faCircleChevronRight, faCircleXmark} from '@fortawesome/free-solid-svg-icons'

import './Gallery.css'

const WSPGallery = ({galleryImages}) => {
    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    const handleKeyPress = useCallback((event) => {
        // handle what happens on key press
        if (event.key === "Escape")
            handleCloseModal();
        if (event.key === "a" || event.key === "A" || event.key === "ArrowLeft")
            prevSlide();
        if (event.key === "d" || event.key === "D" || event.key === "ArrowRight")
            nextSlide();
    }, [prevSlide, nextSlide, handleCloseModal]);


    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

//detecting swipes for gallery-----------------
//---------------------------------------------
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

// the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isRightSwipe = distance > minSwipeDistance
        const isLeftSwipe = distance < -minSwipeDistance
        if (isLeftSwipe)
            prevSlide();
        if (isRightSwipe)
            nextSlide();
    }
    return (
        <div>
            {openModal &&
                <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}/>
                    <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide}/>
                    <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide}/>
                    <div className='fullScreenImage'>

                        <motion.div
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            transition={{duration: 0.5}}
                        >
                            <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                                <img src={galleryImages[slideNumber].img} alt=''/>
                            </div>

                            <br/>
                            <text>Obrázek: {slideNumber + 1} / {galleryImages.length}</text>
                            <br/>
                        </motion.div>

                    </div>
                </div>
            }

            {}

            <div className='galleryWrap'>
                {
                    galleryImages && galleryImages.map((slide, index) => {
                        return (
                            <li
                                className='single'
                                key={index}
                                onClick={() => handleOpenModal(index)}
                            >
                                <img src={slide.img} alt='image'/>
                            </li>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default WSPGallery