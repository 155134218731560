import React from 'react';
import {motion} from 'framer-motion';

export default function AboutProject() {
    return (
        <div className="practic-page-container">
            <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{y: 100}}
                        transition={{default: {duration: 0.5}}}>
                <div className='practic-box-container'>
                    <div className='practic-columns'>
                        <h1 className="intro-header">
                            O hře
                        </h1>
                        <h2>
                            Pár slov úvodem
                        </h2>
                        <p>
                            Jak již bylo v úvodu zmíněno, naše hra se odehrává ve světě Forgotten realms (Zapomenuté
                            říše), konkrétně pak na jeho ústředním kontinentu známém jako Faerûn. Časově je zasazena
                            do doby po konci Moru kouzel, kdy se podoba světa opět změnila a ustálila.
                        </p>
                        <p>
                            Náš příběh se odehrává na severu kontinentu v malé neznámé osadě Stříbrodol, která leží
                            pod Nethrerilskými horami. Na východ se rozkládá Daleký les a na západ o dost temnější a
                            nebezpečnější Vysoký hvozd. Stříbrodol dlouho žil bez většího ohrožení, ale to se prudce
                            změnilo po rozpadu Stříbrného pomezí, které je také známo jako konfederace Luruar. Tento
                            obranný pakt se rozpadl a kvůli tomu se barbaři a nepřátelé ze severu začali dostávat na
                            jih až k Stříbrodolu. Kromě orků, kteří osadu ohrožují se v lesích potulují všelijaké
                            bestie a nestvůry.
                        </p>
                        <p>
                            Do těchto míst míří několik bytostí, přinášející naději, či snad další potíže pro místní
                            život. Budou to následující dny, které rozhodnou o osudu nejen zdejších kopců, ale možná
                            o osudu celého světa!
                        </p>
                        <p>
                            Osudy postav jsou provázané a je jisté, že kromě řešení agend jednotlivých družin dojde
                            k mnoha setkáním, promluvám a konfliktům.
                        </p>
                        <h2>
                            Žánr hry
                        </h2>
                        <p>
                            Hra je na motivy Zapomenutých říší, spadajících do žánru fantasy. Reálie vychází z
                            knih, příběhů a dalších rozsáhlých zdrojů informací z tohoto pestrého světa. My se silně
                            inspirujeme světem, ovšem nebojíme se do něj vložit vlastní kreativní a tvůrčí vizi tím,
                            že některé události v čase posouváme, či pozměňuje. Děj hry není v žádné z knih popsán a
                            bude pouze na hráčích, jak příběh jejich postavy, družin a osady dopadne.
                        </p>
                        <p>
                            Nejedná se nutně o epické fantasy, neboť každá družina má trochu jinou náladu a pojetí.
                            Zatímco některé družiny budou řešit vyloženě hrdinské dobrodružství, jiné družiny zase
                            mohou mít více temnější a sklíčenou atmosféru detektivního hororu. Doporučujeme dobře
                            volit družinu, protože právě ona definuje samotný žánr a styl příběhu, který prožijete.
                        </p>
                        <h2>
                            Styl hry
                        </h2>
                        <p>
                            Hra se stylově řadí mezi obsahový larp se silnými prvky družinových výprav a bohatých
                            osobních vztahů. Dělí se přitom na výpravy jednotlivých družin s vlastními cíli a
                            společnými okamžiky všech skupin v rámci osady. Výpravy jsou intenzivním a akčním
                            zážitkem, kde se určitě setkáte s bojem pomocí larpových zbraní a dobrodružstvím. Dění
                            ve Stříbrodolu je klidnější a nabízí možnost konverzovat, řešit osobní zápletky své
                            postavy a zapojit se do událostí, jež se v osadě budou dít a které budou globálně
                            posouvat příběh a reagovat na dění na výpravách samotných.
                        </p>
                        <p>
                            Ve hře tedy najdete tři vrstvy příběhu. Na povrchu je poměrně přímočarý a jednoduchý
                            soubor událostí, které se v osadě stanou za přítomnosti všech. Hlavní důraz je však
                            kladen na další dvě vrstvy a to příběh družiny a příběh osobní. Příběh družiny se bude
                            posouvat na výpravách, kde se často protne s osobním příběhem některých členů družiny.
                            Na různé rozmluvy, konflikty a jiné povídání čistě osobního ražení bude čas v osadě.
                            Věříme, že promíchání všech těchto tří vrstev vám přinese jedinečný zážitek ze hry
                            samotné!
                        </p>
                        <h2>
                            Herní skupiny
                        </h2>
                        <p>
                            Postavy se dělí do několika družin s tím, že celková nálada, tématika vaší hry bude silně
                            vázaná na družinu, kterou si vyberete. Technicky poté družiny obsahují po 8 předepsaných
                            postav, kdy 4 postavy jsou ženského a 4 postavy mužského pohlaví.
                        </p>
                        <p>
                            Družiny před přihlášením budou doplněny o krátký přehled svých členů, abyste tušili jaké
                            rasy a případně jaké archetypy v nich najdete.
                        </p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}