import React from 'react'
import "./AuthorCard.css"
import ImageComponent from "./ImageComponent";
import {motion} from "framer-motion";
import useIsMobile from "./isMobile";


const Card = ({heading, detail, image, isLeft, hash}) => {
    const isMobile = useIsMobile();
    if(isMobile)
        return(
            <div className='background-gradient'>
                <div className="AuthorContainer">
                    <div className="AuthorText">
                            <h1>
                                {heading}
                            </h1>
                    </div>
                    <motion.div className="ImagePhone"
                        initial={{opacity: 0, x: "-5%"}}
                        whileInView={{opacity: 1, x: "0%"}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <ImageComponent width="400px" src={image} alt='author_img' hash={hash}/>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <div className="AuthorText">
                            <p>
                                {detail}
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        )
    else if (isLeft)
        return (
            <div className='background-gradient'>
                <div className="AuthorContainer">
                    <motion.div className="ImageLeft"
                        initial={{opacity: 0, x: "-5%"}}
                        whileInView={{opacity: 1, x: "0%"}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <ImageComponent width="400px" src={image} alt='author_img' hash={hash}/>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <div className="AuthorText">
                            <h1>
                                {heading}
                            </h1>
                            <p>
                                {detail}
                            </p>
                        </div>
                    </motion.div>
                </div>

            </div>
        )
    else
        return (
            <div className='background-gradient2'>
                <div className="AuthorContainer">
                    <motion.div
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <div className="AuthorText">
                            <h1>
                                {heading}
                            </h1>
                            <p>
                                {detail}
                            </p>
                        </div>
                    </motion.div>
                    <motion.div className="ImageRight"
                        initial={{opacity: 0, x: "5%"}}
                        whileInView={{opacity: 1, x: "0%"}}
                        transition={{duration: 1}}
                        viewport={{once: true}}
                    >
                        <ImageComponent width="400px" src={image} alt='author_img' hash={hash}/>
                    </motion.div>
                </div>
            </div>
        )
}
export default Card