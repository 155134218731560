import React, {useEffect, useState} from 'react';
import {Button} from './Button';
import {Link} from 'react-router-dom';
import './Navbar.css';
import ImageComponent from "./ImageComponent";

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => {
        setClick(!click);

    }
    const closeMobileMenu = () => {
        setClick(false);
        window.scrollTo({top: 0});
    }

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <nav className='navbar'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                <div className='navbar-logo'>
                    <ImageComponent src={'/logo/sf.png'} alt="icon" hash="L4QJWLxZ00%h~qofxbj[00W;-:Mw" width='65%'/>
                </div>
            </Link>
            <div className='navbar-container'>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Úvod
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/about-project'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            O hře
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/companionship'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Skupiny
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/practic'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Praktické
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/about-us'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Autoři
                        </Link>
                    </li>
                    <li>
                        <Link
                            to='/sign-up'
                            className='nav-links-mobile'
                            onClick={closeMobileMenu}
                        >
                            Registrace
                        </Link>
                    </li>
                </ul>
                {button &&
                    <Link
                        to='/sign-up'>
                        <Button buttonStyle='btn--outline'>Registrace</Button>
                    </Link>}
            </div>
        </nav>
    );
}

export default Navbar;
